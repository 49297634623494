import React from "react"
import Layout from "../../../components/layouts/main"
import Badge from "../../../components/badge"
import SEO from "../../../components/seo"
import {PatreonButton, PatreonCorner} from "../../../components/patreon"

const PatreonPage = () => (
  <Layout>
    <SEO
      title="Patreon Supporters"
      description="Patreon Support page for Steven Kraft's Japanese projects, with list of all current and past supporters."
    />
    <h1>Patreon Supporters</h1>

    <h5>Thank You So Much for the Support!</h5>

    <ul>
      <li>Kai <Badge color="var(--patreon)">Early Supporter</Badge></li>
      <li>Josh Coneby <Badge color="var(--patreon)">Early Supporter</Badge></li>
      <li>Liz Ellis <Badge color="var(--patreon)">Early Supporter</Badge></li>
      <li>Namidagawa <Badge color="var(--patreon)">Early Supporter</Badge></li>
      <li>Kristy <Badge color="var(--patreon)">Early Supporter</Badge></li>
      <li>Miles Gard <Badge color="var(--patreon)">Early Supporter</Badge></li>
      <li>Witty Username <Badge color="var(--patreon)">Early Supporter</Badge></li>
      <li>Daniel Kruger <Badge color="var(--patreon)">Early Supporter</Badge></li>
      <li>Keyjin <Badge color="var(--patreon)">Early Supporter</Badge></li>
      <li>Gregor Gomory <Badge color="var(--patreon)">Early Supporter</Badge></li>
      <li>Chris Trystero <Badge color="var(--patreon)">Early Supporter</Badge></li>
      <li>Felix <Badge color="var(--patreon)">Early Supporter</Badge></li>
      <li>Or Paz</li>
      <li>Ralph Edouard II</li>
      <li>Nicholas John Celestin</li>
      <li>Kenny Korn</li>
      <li>Aloha Ninetythree</li>
      <li>Duckling</li>
      <li>Christina Vennegerts</li>
      <li>Macky Padilla</li>
      <li>James Wongvipat</li>
      <li>Vlad Barbulescu</li>
      <li>Ash</li>
      <li>apc</li>
      <li>Kevin S.</li>
      <li>Ingemar Berg</li>
      <li>Jacob Millner</li>
      <li>Ryuu13</li>
      <li>Tess Younker</li>
      <li>Sam Crerar</li>
      <li>Krista Esau</li>
      <li>Guillaume Berthon</li>
      <li>Dithari</li>
      <li>Quasi a la Modo</li>
      <li>Kaz Ashton</li>
      <li>Sarah Loves Japan</li>
      <li>man1001</li>
      <li>Mateusz Gozdan</li>
      <li>C.S. Chao</li>
      <li>Rosa Kucharska</li>
      <li>Simon L</li>
      <li>Hosanna Tolman</li>
      <li>Jade Iqbal</li>
      <li>Lord Candyhands</li>
      <li>Kyle Riley</li>
      <li>Mark Rossiter</li>
      <li>Kristopher</li>
      <li>David</li>
      <li>Brian</li>
      <li>Raphael Bekhor</li>
      <li>Justin Williams</li>
      <li>Yannick Ruile</li>
      <li>Takahashi Yuji</li>
      <li>bogrum</li>
      <li>Isaac Forman</li>
      <li>Peter Knapp</li>
      <li>Kevin Asistores</li>
      <li>Kristine Chheng</li>
      <li>Saroupille</li>
      <li>Joshua Gonzalez</li>
      <li>Jason Rose</li>
      <li>Felix S Isatia</li>
      <li>John Gillies</li>
      <li>nutberry crunch</li>
      <li>Adam Russell</li>
      <li>Evgenii Shchemelev</li>
      <li>Walther</li>
      <li>Jake Arstein</li>
      <li>Martín Langberg</li>
      <li>ZerataX</li>
      <li>Irina Kuzmina</li>
      <li>Kai (#2)</li>
      <li>Hayli</li>
      <li>Matěj Heger</li>
      <li>Miku Piku</li>
      <li>Andre Allen</li>
      <li>Carlos Giraldo</li>
      <li>Manuel Cruz</li>
      <li>Thomas Lloyd</li>
      <li>Albert Tran</li>
      <li>Morgan Ross</li>
      <li>Jarrod Goetz</li>
      <li>Wesley De Oliveira Fernandes</li>
      <li>Ian Burry</li>
      <li>GB</li>
      <li>Lee Adey</li>
      <li>Anne Lacoste</li>
      <li>Alexa Jeanpierre</li>
      <li>Irina</li>
      <li>Charlotte Thompson</li>
      <li>Timothy Johnson</li>
      <li>KAJ C</li>
      <li>Anthony Herndon</li>
      <li>Anna Murray</li>
      <li>nikolaspcc .</li>
      <li>paul nicholas</li>
      <li>Katelyn</li>
      <li>mwenzel</li>
      <li>BubberDuck</li>
      <li>John Arthemis</li>
      <li>Jack</li>
      <li>Paula</li>
      <li>x5444</li>
      <li>Jeffrey</li>
      <li>Nadi-Chan</li>
      <li>Bruno Mendonca</li>
      <li>Alex Sinclair</li>
      <li>Hector Castellon</li>
      <li>andrew st.denis</li>
      <li>Madison Denton</li>
      <li>Alicia Bell</li>
      <li>Chynna Gibbs</li>
      <li>Carlos O</li>
      <li>Joey Palazzo</li>
      <li>Isaac Sarmiento</li>
      <li>storode</li>
      <li>Jo You</li>
      <li>Silas Lee</li>
      <li>Chris Funkhouser</li>
      <li>texasyogi</li>
      <li>Sean Glass</li>
      <li>Matthew Rees</li>
      <li>MuzNeo</li>
      <li>Toby Rufeo</li>
      <li>Raven</li>
      <li>Uta</li>
      <li>nd</li>
      <li>Madwraith</li>
      <li>Juli Thompson</li>
      <li>Dylan Kuzmick</li>
      <li>Francesco Bianco</li>
      <li>joe Truman</li>
      <li>Abby Kingman</li>
      <li>Kroshucko</li>
      <li>Monica</li>
      <li>mrrryy</li>
      <li>Jen Grasby</li>
      <li>Severine Van Hoecke</li>
      <li>Andy Felber</li>
      <li>Thuy Nguyen</li>
      <li>Nathan Depew</li>
      <li>cosmii</li>
      <li>Pl</li>
      <li>Jason Ji</li>
      <li>Kaitokun</li>
      <li>Quentin Verchere</li>
      <li>Nima Movasseghi</li>
      <li>Adler</li>
      <li>Matt Drance</li>
      <li>L Brown</li>
      <li>Christopher Cripe</li>
      <li>Caitlin</li>
      <li>marumarumaru</li>
      <li>Dustin Thaler</li>
      <li>Jordan Son</li>
      <li>Benerly</li>
    </ul>

    <p><br /><br /></p>

    <p><PatreonButton /></p>
    <PatreonCorner />

  </Layout>
)

export default PatreonPage
